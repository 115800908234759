import React, { useState, useEffect } from 'react';
import './Reviews.css';
import Trustpilot from '../../assets/Reviews/Trustpilot_circle.svg';
import TrustpilotPng from '../../assets/Reviews/Trustpilot_circle.png';
import BBB from '../../assets/Reviews/BBB_circle.svg';
import BBBPng from '../../assets/Reviews/BBB_circle.png';
import Google from '../../assets/Reviews/Google_circle.svg';
import GooglePng from '../../assets/Reviews/Google_circle.png';
import Facebook from '../../assets/Reviews/Facebook_circle.svg';
import FacebookPng from '../../assets/Reviews/Facebook_circle.png';
import arrowRight from '../../assets/arrowRight.svg';
import arrowRightPng from '../../assets/Quiz/NextArrow.png';
import {connect} from 'react-redux';
import { compose } from 'recompose';
import MobileDetect from 'mobile-detect';
import { isMacOS } from './../../utils/isMacOS'
import { isFirefox } from './../../utils/isFirefox'

function  isMobile () {
  const md = new MobileDetect(window.navigator.userAgent);
  return md.mobile();
}

const Reviews = (props) => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const googleReviewURL = isMobile() ? 'https://www.google.com/maps/place/Choice+Mutual,+675+W+Moana+Ln+UNIT+101,+Reno,+NV+89509/@39.4916612,-119.8038901,16z/data=!4m2!3m1!1s0x8099413423a12985:0xe2aadd8435109160' : 'https://www.google.com/search?client=firefox-b-1-d&q=choice+mutual#lrd=0x8099413423a12985:0xe2aadd8435109160,1,,' ; 
  let BBBText = windowWidth >= 1120 ? "Better Business Bureau" : "BBB"

  useEffect(() => {
    const handleWindowResize = () => {
        setWindowWidth(window.innerWidth);
    };
    
    window.addEventListener('resize', handleWindowResize);

    return () => {
        window.removeEventListener('resize', handleWindowResize);
    }
  }, []);

  return (
    <div className={`reviews ${props.loading ? "d-none" : ""}`}>
      <div className="title">
        <h3>Hear what our customers have to say about their Choice Mutual experience.</h3>
      </div>
      <div className="d-flex flex-wrap col-wrapper mb-md-1">
        <div className="d-flex flex-column align-items-center col-6 col-md-3 mt-3 px-2 mb-md-3 review">
          <img src={!isMacOS() && isFirefox() ? TrustpilotPng : Trustpilot} className="social_circle mb-2" alt="Trustpilot"/>
          <h4 className="mt-lgxl-1">Trustpilot</h4>
          <div className="text-center mb-2 mt-lgxl-1"><b>‘Excellent’</b> rating with 5/5 stars from over 1300 reviews</div>
          <a href='https://www.trustpilot.com/review/choicemutual.com?utm_medium=trustbox&utm_source=Grid' target="_blank" className="green-link-with-arrow mt-lgxl-1">Read reviews<img src={ !isMacOS() && isFirefox() ? arrowRightPng : arrowRight}/></a>
        </div>
        <div className="d-flex flex-column align-items-center col-6 col-md-3 mt-3 px-2 mb-md-3 review">
          <img src={!isMacOS() && isFirefox() ? BBBPng : BBB} className="social_circle mb-2" alt="BBB"/>
          <h4 className="mt-lgxl-1">{BBBText}</h4>
          <div className="text-center mb-2 mt-lgxl-1">Fully accredited with an <b>A+</b> rating and no complaints</div>
          <a href='https://www.bbb.org/us/nv/reno/profile/life-insurance/choice-mutual-1166-90019781' target="_blank" className="green-link-with-arrow mt-lgxl-1">See profile<img src={ !isMacOS() && isFirefox() ? arrowRightPng : arrowRight}/></a>
        </div>
        <div className="d-flex flex-column align-items-center col-6 col-md-3 mt-3 px-2 mb-md-3 review">
          <img src={!isMacOS() && isFirefox() ? GooglePng : Google} className="social_circle mb-2" alt="Google"/>
          <h4 className="mt-lgxl-1">Google</h4>
          <div className="text-center mb-2 mt-lgxl-1"><b>4.9/5</b> star rating with 100+ rave customer reviews</div>
          <a href={googleReviewURL} target="_blank" className="green-link-with-arrow mt-lgxl-1">Read reviews<img src={ !isMacOS() && isFirefox() ? arrowRightPng : arrowRight}/></a>
        </div>
        <div className="d-flex flex-column align-items-center col-6 col-md-3 mt-3 px-2 mb-md-3 review">
          <img src={!isMacOS() && isFirefox() ? FacebookPng : Facebook} className="social_circle mb-2" alt="Facebook"/>
          <h4 className="mt-lgxl-1">Facebook</h4>
          <div className="text-center mb-2 mt-lgxl-1">A perfect <b>5-star</b> review average from our clients</div>
          <a href='https://www.facebook.com/choicemutual/' target="_blank" className="green-link-with-arrow mt-lgxl-1">Read reviews<img src={ !isMacOS() && isFirefox() ? arrowRightPng : arrowRight}/></a>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps  = (state) => ({
  loading: state.consumer.loading
})

export default compose(
  connect(mapStateToProps))(Reviews);

